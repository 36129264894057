import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: { name: 'LoginView' }
  // },
  {
    name: 'IndexView',
    path: '/',
    component: () => import('@/views/index/IndexView'),
    meta: { title: 'Minu' }
  },
  {
    name: 'LoginView',
    path: '/login',
    component: () => import('@/views/login/LoginView'),
    meta: { title: 'Minu - Log In' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.APP_URL,
  routes
})

export default router
